/* VENDOR */
import React, { Component } from 'react'
import { Tabs } from 'antd-mobile'
import { Button } from 'antd'
import { ArrowLeftOutlined, PlusOutlined, CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

/* APPLICATION */
import { 
    Playlists, 
    Playlist, 
    SplashHeader, 
    Player as PlayerView,
    PlaylistEditor
} from 'components'
import { generate, delay, copy } from 'tools'
import config from 'config'

import connector from './connector'
import Player from './player'
import './main.scss'

class Main extends Component {
    
    constructor ( props ) {
        super( props )

        this.state = {
            tab: 'playlists',

            loading: true,
            edit:    false,
            playing: false,
            shuffle: false,

            current: null,
            draft:   null,
            track:   null,
            flush:   null,
            header:  null,

            time:   0,
            length: 0
        }

        this.player = new Player( this )
        this.set = generate.set( this )
    }

    componentDidMount () {
        this.props.getLibrary( 'flo' )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { library } = this.props,
            { tab } = this.state

        ;( library !== prevProps.library && library ) && ( this.set.state({ loading: false, header: generate.uniq() }) )
        ;( tab !== prevState.tab ) && ( this.set.header( generate.uniq() ) )
    }

    allTracks = () =>
        {
            const
                { library, userId } = this.props

            if ( !library.playlists ) return null

            return library.playlists.find( p => p.id === `all_${userId}`).tracks
        }

    select = playlist =>
        {
            this.set.state({
                current: playlist,
                draft:   null,
                flush:   generate.uniq()
            })
            setTimeout( () => this.set.tab( 'current' ), 100 )
        }

    create = () =>
        {
            this.set.state({
                edit: false,
                draft: { ...config.drafts.playlist( this.props.userId ) }
            })
            setTimeout( () => this.set.tab( 'new' ), 100 )
        }

    edit = () => 
        {
            this.set.state({
                edit: true,
                draft: null,
                tab: 'edit'
            })
        }

    save = () =>
        {
            const
                { draft, current, edit } = this.state

            if ( draft ) {
                this.props.createPlaylist({
                    ...draft,
                    tracks: draft.tracks.map( t => t.id )
                })
                this.set.loading( true )
            } else if ( edit ) {
                this.set.state({
                    edit: false,
                    tab: 'current' 
                })
                this.props.updatePlaylist({
                    ...current,
                    tracks: current.tracks.map( t => t.id )
                })
            }
        }

    update = key => track => 
        {
            const
                tracks = copy.array( this.state[key].tracks ),
                found = tracks.find( t => t.id === track.id )

            if ( !!tracks.find( t => t.id === track.id ) ) {
                tracks.splice( tracks.indexOf( found ), 1 )
            } else {
                tracks.push( track )
            }

            this.set[key]({ ...this.state[key], tracks })
        }

    rename = key => title => 
        this.set[key]({
            ...this.state[key],
            title
        })

    picture = key => image =>
        this.set[key]({
            ...this.state[key],
            image
        })

    randomize = arr =>
        {
            let 
                j, x, i,
                res = [ ...arr ]

            for (i = arr.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1))
                x = res[i]
                res[i] = res[j]
                res[j] = x
            }
            return res
        }

    play = newTrack =>
        {
            const
                { track } = this.state

            if ( !newTrack || !newTrack.id ) {
                this.player.play()
                return
            }

            if ( !track || track.id !== newTrack.id ) {
                this.set.track( newTrack )
                this.player.load( newTrack )
            } else {
                this.player.play()
            }
        }

    pause  = () => this.player.pause()
    resume = () => this.player.resume()
    seek   = time => this.player.seek( time )

    shuffle = () =>
        {
            const
                { library } = this.props,
                { shuffle, current } = this.state
            
            if ( !current ) {
                this.set.shuffle( false )
                return
            }

            if ( shuffle ) {
                this.set.state({
                    shuffle: false,
                    current: library.playlists.find( p => p.id === current.id ),
                    track: { ...this.state.track }
                })
            } else {
                this.set.state({
                    shuffle: true,
                    current: {
                        ...current,
                        tracks: this.randomize( current.tracks )
                    }
                }, () => {
                    this.play( this.state.current.tracks[0] )
                })
            }
        }
    
    next = () =>
        {
            const
                { current, track } = this.state,
                next = current.tracks.findIndex( t => t.id === track.id ) + 1

            if ( next >= current.tracks.length ) {
                this.play( current.tracks[0] )
            }

            this.play( current.tracks[next] )
        }
    
    prev = () =>
        {
            const
                { current, track } = this.state,
                prev = current.tracks.findIndex( t => t.id === track.id ) - 1

            if ( prev < 0 ) {
                this.play( current.tracks[current.tracks.length - 1] )
            }

            this.play( current.tracks[prev] )
        }

    restricted = playlist => 
        (
            !playlist ||
            playlist.id.substr( 0, 4 ) === 'all_' || 
            playlist.id.substr( 0, 11 ) === 'unassigned_'
        )

    head = () =>
        {
            const
                { tab, current } = this.state

            switch ( tab ) {
                case 'playlists':
                    return (
                        <React.Fragment>
                            <span className="head-title">Playlists</span>
                            <Button
                                className = "offset"
                                type = "text" 
                                icon = { <PlusOutlined /> }
                                onClick = { this.create }
                            />
                        </React.Fragment>
                    )
                case 'current':
                    return (
                        <React.Fragment>
                            <Button
                                type = "text" 
                                icon = { <ArrowLeftOutlined /> }
                                onClick = { () => this.set.tab( 'playlists' ) }
                            />
                            <span className="head-title">{ current.title }</span>
                            {
                                ( !this.restricted( current ) ) && (
                                    <React.Fragment>
                                        <Button
                                            className = "offset"
                                            type = "text" 
                                            icon = { <EditOutlined /> }
                                            onClick = { this.edit }
                                        />
                                        <Button
                                            type = "text" 
                                            icon = { <DeleteOutlined /> }
                                            onClick = { this.remove }
                                        />
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                case 'new':
                    return (
                        <React.Fragment>
                            <Button
                                type = "text" 
                                icon = { <ArrowLeftOutlined /> }
                                onClick = { () => {
                                    this.set.tab( 'playlists' )
                                    setTimeout( () => this.set.draft( null ), 500 )
                                }}
                            />
                            <span className="head-title">Create Playlist</span>
                            <Button
                                className = "offset"
                                type = "text" 
                                icon = { <CheckOutlined /> }
                                onClick = { this.save }
                            />
                        </React.Fragment>
                    )
                case 'edit':
                    return (
                        <React.Fragment>
                            <Button
                                type = "text" 
                                icon = { <ArrowLeftOutlined /> }
                                onClick = { () => {
                                    this.set.state({
                                        edit: false,
                                        tab: 'current' 
                                    })
                                    setTimeout( () => {
                                        this.set.state({
                                            current: { ...this.props.library.playlists.find( p => p.id === this.state.current.id ) }
                                        })
                                    }, 500 )
                                }}
                            />
                            <span className="head-title">Edit Playlist</span>
                            <Button
                                className = "offset"
                                type = "text" 
                                icon = { <CheckOutlined /> }
                                onClick = { this.save }
                            />
                        </React.Fragment>
                    )
                default:
                    console.log( 'unknown tab:', tab )
                    return null
            }
        }

    render () {
        const
            { library } = this.props,
            { loading, tab, header } = this.state,
            { flush, current, track, draft, edit } = this.state,
            { playing, shuffle, time, length } = this.state,
            tabs = [
                { title: 'Playlists', key: 'playlists' }
            ]

        ;( current && !draft ) && ( tabs.push({ title: current ? current.title : '', key: 'current' }) )
        ;( draft && !edit ) && ( tabs.push({ title: 'Add Playlist', key: 'new' }) )
        ;( edit && !draft ) && ( tabs.push({ title: 'Edit Playlist', key: 'edit' }) )

        return (
            <section className="app-page app-page-main">
                <SplashHeader
                    full    = { loading && !library.tracks }
                    loading = { loading }
                    flush   = { header }

                    background = { current && current.image }
                >
                    {
                        loading
                            ? null
                            : this.head()
                    }
                </SplashHeader>
                <Tabs 
                    tabs = { tabs }
                    page = { loading ? 'loading' : tab }

                    onChange   = { this.set.extract( 'tab', 'key' ) }
                    onTabClick = { delay( this.set.extract( 'tab', 'key' ), 200 ) }
                >
                    <div key="playlists" className="page-content">
                        <Playlists
                            playlists = { library.playlists }
                            current   = { current }
                            draft     = { draft }
                            flush     = { flush }

                            onSelect = { this.select }
                            onCreate = { this.create }
                        />
                    </div>
                    <div key="current" className="page-content">
                        <Playlist
                            canScroll = { tab === 'current' }

                            data    = { edit ? library.playlists.find( p => p.id === current.id ) : current }
                            current = { track }
                            playing = { playing }

                            onPlay   = { this.play }
                            onPause  = { this.pause }
                        />
                    </div>
                    <div key="new" className="page-content">
                        <PlaylistEditor
                            data   = { draft }
                            tracks = { this.allTracks() }

                            setPicture = { this.picture( 'draft' ) }
                            onRename   = { this.rename( 'draft' ) }
                            onSelect   = { this.update( 'draft' ) }
                            onSave     = { this.save }
                        />
                    </div>
                    <div key="edit" className="page-content">
                        <PlaylistEditor
                            data   = { current }
                            tracks = { this.allTracks() }

                            setPicture = { this.picture( 'current' ) }
                            onRename   = { this.rename( 'current' ) }
                            onSelect   = { this.update( 'current' ) }
                            onSave     = { this.save }
                        />
                    </div>
                </Tabs>
                <PlayerView
                    parent   = { this }
                    playlist = { current }
                    track    = { track }

                    small   = { tab !== 'current' }
                    playing = { playing }
                    shuffle = { shuffle }
                    loop    = { true }
                    
                    time    = { time }
                    total   = { length }

                    onList    = { !edit && !draft ? () => this.set.tab( 'current' ) : null }
                    onShuffle = { this.shuffle }
                    onNext    = { this.next }
                    onPrev    = { this.prev }
                    onPlay    = { this.play }
                    onPause   = { this.pause }
                    onSeek    = { this.seek }
                />
            </section>
        )
    }
}

export default connector( Main )