/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/* APPLICATION */
import { Icons, TrackInfo } from 'components'

import './track.scss'

class Track extends Component {

    static propTypes = {
        playing:   PropTypes.bool,
        canScroll: PropTypes.bool,

        current:   PropTypes.object,
        data:    PropTypes.object,

        onPlay:  PropTypes.func
    }

    node = null

    componentDidUpdate( prevProps ) {
        const
            { current, data, canScroll } = this.props,
            active = current && current.id === data.id

        ;( current !== prevProps.current && active ) && ( this.scroll() )
        ;( canScroll !== prevProps.canScroll && canScroll && current ) && ( this.scroll() )
    }

    visible = () =>
        {
            if ( !this.node ) return false

            const
                rect = this.node.getBoundingClientRect(),
                container = document.querySelector( '.am-tabs-pane-wrap-active' ).getBoundingClientRect()

            return !(
                rect.bottom < container.top || 
                rect.top > container.bottom || 
                rect.bottom > container.bottom || 
                rect.top < container.top 
            )
        }

    scroll = () =>
        setTimeout( () => {
            if ( this.node && !this.visible() && this.props.canScroll ) {
                this.node.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }
        }, 100 )

    render () {
        const
            { playing, current, data } = this.props,
            active = current && current.id === data.id,
            cls = classNames( 'track', active && 'current' )
            
        return (
            <div className={cls} onClick={this.props.onPlay} ref={node => this.node = node}>
                <div className="track-play-button">
                    {
                        playing && active
                            ? <Icons.pause />
                            : <Icons.play />
                    }
                </div>
                <TrackInfo
                    track = { data }
                />
            </div>
        )
    }
}

export default Track
