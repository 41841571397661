import React from 'react'

export const spinner = () => (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotating"><path d="M12 22C11.4247 22 10.9583 21.5336 10.9583 20.9583C10.9583 20.383 11.4247 19.9167 12 19.9167C16.3723 19.9167 19.9167 16.3723 19.9167 12C19.9167 7.62775 16.3723 4.08333 12 4.08333C7.62775 4.08333 4.08333 7.62775 4.08333 12C4.08333 12.9175 4.23901 13.8121 4.53991 14.6569C4.73295 15.1989 4.4501 15.7947 3.90815 15.9877C3.36621 16.1808 2.77039 15.8979 2.57736 15.356C2.1968 14.2876 2 13.1565 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z" fill="#009999"/></svg>
)

export const play = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" fill="none">
        <path fill="#fff" d="M11.1667 5.26795c1.3334.7698 1.3334 2.6943 0 3.4641L3.41675 13.2065c-1.33333.7698-3.000002-.1924-3.000002-1.732l.000001-8.94897C.416749.985933 2.08342.0236833 3.41675.793484L11.1667 5.26795z"/>
    </svg>
)

export const pause = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
        <rect width="3" height="14" x="4" y="3" fill="#fff" rx="1.5"/>
        <rect width="3" height="14" x="13" y="3" fill="#fff" rx="1.5"/>
    </svg>
)