export const title = track =>
    (
        track.title || fromFilename( track, 'title' )
    )

export const author = track => 
    (
        track.remixer || 
        track.artist || 
        track.band ||
        track.composer || 
        track.original_artist || 
        fromFilename( track, 'author' )
    )

export const fromFilename = ( track, key ) =>
    {
        if ( track.filename.indexOf( '-' ) < 0 ) return track.filename

        const
            noext = track.filename.split( '.' )[0],
            split = noext.split( '-' ),
            author = split[0].trim(),
            title = split[1].trim(),
            res = {
                author,
                title
            }

        if ( !res[key] ) return track.filename

        return res[key]
    }

export const base64 = ( file, callback ) =>
    {
        const 
            reader = new FileReader()

        reader.addEventListener( 'load', () => callback( reader.result ) )
        reader.readAsDataURL( file )
    }