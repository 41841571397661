/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '@ant-design/icons'

/* APPLICATION */
import { Center, Icons } from 'components'
import './loading.scss'

class Loading extends Component {

    static propTypes = {
        visible: PropTypes.bool
    }

    render () {
        const
            { visible } = this.props,
            cls = classNames( 'loading-overlay', visible && 'visible' )

        return (
            <div className={cls}>
                <Center>
                    <Icon component={Icons.spinner} />
                </Center>
            </div>
        )
    }
}

export default Loading
