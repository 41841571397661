export const by = ( key, list, value ) => list.find( item => item[key] === value )

export const inProps = ( needle, keys, obj ) =>
    {
        const
            props = keys === '*' 
                ? Object.keys( obj )
                : keys

        let
            res = false

        props.forEach( key => {
            res = res || ( obj[key] && obj[key].toString().toLowerCase().indexOf( needle.toLowerCase() ) > -1 )
        })

        return res
    }