/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/* APPLICATION */
import { generate } from 'tools'
import config from 'config'
import './playlist-item.scss'

class PlaylistItem extends Component {

    static propTypes = {
        flush:   PropTypes.string,
        data:    PropTypes.object,
        current: PropTypes.bool,
        action:  PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            anim: false
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { flush } = this.props

        if ( flush !== prevProps.flush ) {
            this.set.anim( true )
            setTimeout( () => this.set.anim( false ), 500 )
        }
    }

    render () {
        const
            { data, current } = this.props,
            { anim } = this.state,
            cls = classNames( 'playlist-item', ( anim && current ) && 'current' )

        return (
            <div className={cls} onClick={ this.props.action }>
                {
                    data.image && (
                        <img src={`${config.api.images}/${data.image}`} alt={data.title} />
                    )
                }
                <span className="playlist-image-placeholder">
                    { data.title }
                </span>
            </div>
        )
    }
}

export default PlaylistItem
