import config from 'config'

export default class Player {
    constructor ( parent ) {
        this.parent = parent
        this.player = new Audio()
        this.events()
    }

    dispose = () =>
        {

        }

    events = () =>
        {
            this.player.addEventListener( 'pause', this.status )
            this.player.addEventListener( 'play', this.status )
            this.player.addEventListener( 'timeupdate', this.status )
            this.player.addEventListener( 'ended', this.next )
        }

    status = () =>
        {
            this.parent.set.state({
                playing: !this.player.paused,
                time: this.player.currentTime,
                length: this.player.duration || 0
            })
        }

    play  = () => this.player.play()
    pause = () => this.player.pause()
    
    seek  = time => 
        {
            this.player.currentTime = time
            this.play()
        }
    
    next = () =>
        {
            this.parent.next()
        }
    
    playing = () => !this.player.paused

    load = track =>
        {
            const
                title = track.title
                    ? ( track.artist ? `${track.title} - ${track.artist}` : track.title )
                    : track.filename

            this.player.src = `${config.api.tracks}/${track.filename}`
            document.title = `${title} / Skoglyd`
            this.play()
        }
}