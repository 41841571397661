/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ForwardOutlined, BackwardOutlined, SwapOutlined, RetweetOutlined } from '@ant-design/icons'

/* APPLICATION */
import { Icons, TrackInfo } from 'components'
import { generate } from 'tools'

import './player.scss'

class Player extends Component {

    static propTypes = {
        playing: PropTypes.bool,
        small:   PropTypes.bool,
        shuffle: PropTypes.bool,
        loop:    PropTypes.bool,

        playlist: PropTypes.object,
        track:    PropTypes.object,

        total: PropTypes.number,
        time:  PropTypes.number,

        onList:  PropTypes.func,
        onNext:  PropTypes.func,
        onPrev:  PropTypes.func,
        onPlay:  PropTypes.func,
        onPause: PropTypes.func,
        onSeek:  PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            start: null,
            current: null,

            catched:  false,
            small:    props.small,
            resizing: false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        document.addEventListener( 'touchmove', this.move )
        document.addEventListener( 'touchend', this.drop )
    }

    componentWillUnmount () {
        document.removeEventListener( 'touchmove', this.move )
        document.removeEventListener( 'touchend', this.drop )
    }

    componentDidUpdate ( prevProps ) {
        const
            { small } = this.props

        if ( small !== prevProps.small ) {
            this.set.resizing( true )
            setTimeout( () => this.set.state({
                resizing: false,
                small
            }), 300 )
        }
    }

    drag = e => 
        this.set.state({
            start: e.nativeEvent,
            current: e.nativeEvent,
            catched: true
        })

    move = e =>
        {
            if ( !this.state.catched ) return
            this.set.current( e )
        }

    drop = () =>
        {
            if ( !this.state.catched ) return
            const
                { total } = this.props,
                search = this.search()

            this.props.onSeek( total * ( search / 100 ) )

            setTimeout( () => {
                this.set.state({
                    start: null,
                    current: null,
                    catched: false
                })
            }, 100 )
        }

    search = () =>
        {
            const
                { time, total } = this.props,
                { catched, current } = this.state

            if ( catched ) {
                return ( current.touches[0].clientX / window.innerWidth ) * 100  
            }

            if ( !time || !total ) return 0

            return ( time / total ) * 100
        }

    render () {
        const
            { track, playlist, playing, shuffle, loop } = this.props,
            { small, resizing } = this.state,
            search = this.search(),
            
            cls = classNames( 
                'player', 
                !!track && 'visible', 
                small && 'small',
                resizing && 'resizing'
            ),

            shuffleCls = classNames(
                "player-control player-control-side control-shuffle",
                shuffle && 'active'
            ),

            loopCls = classNames(
                "player-control player-control-side control-loop",
                loop && 'active'
            )

        return (
            <div className={cls}>
                <section className="player-search" onTouchStart={this.drag}>
                    <div className="player-search-bar" />
                    <div className="player-search-loaded" style={{ width: `${search}%` }} />
                    <div className="player-search-dot" style={{ left: `${search}%`}} />
                </section>
                <header className="player-header" onClick={this.props.onList}>
                    <TrackInfo
                        track = { track }
                        image = { playlist && playlist.image }
                    />
                </header>
                <section className="player-controls">
                    <div className={shuffleCls} onClick={this.props.onShuffle}>
                        <SwapOutlined />
                    </div>
                    <div className="player-control control-prev" onClick={this.props.onPrev}>
                        <BackwardOutlined />
                    </div>
                    <div 
                        className = "player-control control-play"
                        onClick   = { playing ? this.props.onPause : this.props.onPlay }
                    >
                        {
                            playing
                                ? <Icons.pause />
                                : <Icons.play />
                        }
                    </div>
                    <div className="player-control control-next" onClick={this.props.onNext}>
                        <ForwardOutlined />
                    </div>
                    <div className={loopCls} onClick={this.props.onLoop}>
                        <RetweetOutlined />
                    </div>
                </section>
            </div>
        )
    }
}

export default Player
