/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { Tracks } from 'components'
import './playlist.scss'

class Playlist extends Component {

    static propTypes = {
        canScroll: PropTypes.bool,
        playing:   PropTypes.bool,

        data:    PropTypes.object,
        current: PropTypes.any,

        onPlay:  PropTypes.func,
        onPause: PropTypes.func
    }

    render () {
        const
            { data, current, playing, canScroll } = this.props

        return (
            <div className="playlist">
                <Tracks
                    canScroll = { canScroll }
                    playing   = { playing }

                    tracks  = { data && data.tracks }
                    current = { current }

                    onPlay  = { this.props.onPlay }
                    onPause = { this.props.onPause }
                />
            </div>
        )
    }
}

export default Playlist
