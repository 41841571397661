import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from 'reduxigen'
import moment from 'moment'

const
    saved = {
        //menuOpen: localStorage.getItem( `${config.ui.prefix}_ui_menu_open` )
    }

export const initialState = {
    auth: {
        lastTry: moment()
    },
    library: {
        playlist: null
    },
    saved
}

export default createStore(
    rootReducer( initialState ),
    applyMiddleware( thunk )
)
