/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/* APPLICATION */
import { PlaylistItem } from 'components'
import './playlists.scss'

class Playlists extends Component {

    static propTypes = {
        flush:     PropTypes.string,
        current:   PropTypes.object,
        draft:     PropTypes.object,
        playlists: PropTypes.array,

        onSelect: PropTypes.func,
        onCreate: PropTypes.func
    }

    select = item => () => this.props.onSelect( item )

    item = item =>
        (
            <li
                key       = { item.id } 
                className = "playlist-item-wrapper" 
            >
                <PlaylistItem 
                    data    = { item }
                    flush   = { this.props.flush }
                    current = { this.props.current && !this.props.draft && item.id === this.props.current.id }  
                    action  = { this.select( item ) }
                />
            </li>
        )

    render () {
        const
            { playlists, draft } = this.props,
            cls = classNames(
                'playlist-item',
                'as-button',
                draft && 'current'
            )

        return (
            <section className="playlists">
                <ul className="playlists-items">
                    {
                        playlists && playlists.map( this.item )
                    }
                    {
                        this.props.onCreate && (
                            <li
                                key       = "new"
                                className = "playlist-item-wrapper"
                            >
                                <span className={cls} onClick={this.props.onCreate}>
                                    <span className="playlist-image-placeholder">+</span>
                                </span>
                            </li>
                        )
                    }
                </ul>
            </section>
        )
    }
}

export default Playlists
