/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Upload } from 'antd'
import { SearchOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'

/* APPLICATION */
import { TracksSelect } from 'components'
import { generate, find } from 'tools'
import config from 'config'

import './playlist-editor.scss'

class PlaylistEditor extends Component {

    static propTypes = {
        data:     PropTypes.object,
        tracks:   PropTypes.array,

        setPicture: PropTypes.func,
        onRename:   PropTypes.func,
        onSelect:   PropTypes.func,
        onSave:     PropTypes.func
    }

    constructor ( props ) {
        super( props )
        
        this.state = {
            search: '',
            uploading: false
        }

        this.set = generate.set( this )
    }

    rename = e => this.props.onRename( e.target.value )

    upload = info => 
        {
            if ( info.file.status === 'uploading' ) {
                this.set.uploading( true )
                return
            }

            if ( info.file.status === 'done' ) {
                this.props.setPicture( info.file.name )
                this.set.uploading( false )
            }

            if ( info.file.status === 'error' ) {
                this.set.uploading( false )
            }
        }

    render () {
        const
            { tracks, data } = this.props,
            { search, uploading } = this.state,
            filtered = search
                ? tracks.filter( t => find.inProps( search, '*', t ) )
                : tracks

        return (
            <div className="playlist">
                <header className="editor-header">
                    <div 
                        className = "editor-image-uploader" 
                        style     = {{
                            backgroundImage: data.image 
                                ? `url(${config.api.images}/${data.image})`
                                : 'none'
                        }}
                    >
                        <Upload
                            showUploadList = { false }

                            name      = "picture"
                            listType  = "picture-card"
                            className = "picture-uploader"
                            action    = {`${config.api.upload}/?type=playlist-image`}

                            onChange  = { this.upload }
                        >
                            <div>
                                {
                                    uploading 
                                        ? <LoadingOutlined /> 
                                        : <PlusOutlined />
                                }
                            </div>
                        </Upload>
                    </div>
                    <div className="editor-form">
                        <Input
                            value    = { data.title } 
                            onChange = { this.rename }
                        />
                    </div>
                </header>
                    <Input
                        prefix   = { <SearchOutlined /> }
                        value    = { search }
                        onChange = { this.set.byval( 'search' ) }
                    />
                <TracksSelect
                    tracks   = { filtered }
                    selected = { data.tracks }
                    onSelect = { this.props.onSelect }
                />
            </div>
        )
    }
}

export default PlaylistEditor