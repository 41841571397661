export const set = cmp =>
    {
        const 
            res = {},
            keys = Object.keys( cmp.state )

        keys.forEach( key => {
            res[ key ] = ( val, cb ) => cmp.setState( { [key]: val }, typeof cb === 'function' ? cb : void 0 )
        })

        res.state = ( data, cb ) => cmp.setState( data, typeof cb === 'function' ? cb : void 0 )
        res.extract = ( key, sub ) => ( val, cb ) => cmp.setState({ [key]: val[sub] }, typeof cb === 'function' ? cb : void 0 )
        res.byval   = ( key, cb ) => e => res[key]( e ? e.target.value : '', cb )
        res.bycheck = ( key, cb ) => e => res[key]( e ? e.target.checked : '', cb )
        res.toggle  = ( key, cb ) => () => res[key]( !cmp.state[key], cb )
        res.delayed = ( key, cb ) => val => () => res[key]( val, cb )
        res.deval   = ( key, cb ) => e => res[key]( e ? e.target.value : '', cb )
        res.decheck = ( key, cb ) => e => res[key]( e ? e.target.checked : '', cb )

        return res
    }

export const addState = ( cmp, add ) =>
    {
        cmp.state = cmp.state
            ? { ...cmp.state, ...add }
            : add

        cmp.set = set( cmp )
    }

export const range = ( min, max, base ) =>
    {
        const
            zero = base || 0,
            res = []

        for ( let i = zero + min; i <= zero + max; i++ ) {
            res.push( i )
        }

        return res
    }

export const uniq = () => '_' + Math.random().toString(36).substr(2, 9)