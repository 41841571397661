/* IMPORT */
import { connect } from 'react-redux'
import { libraryActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    token:   state.auth.token,
    library: state.library,
    user:    'flo',
    userId:  '23523-235wegsdg'
})

const allActions = {
    ...libraryActions
}

export default connect( mapStateToProps, allActions )