/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { extract } from 'tools'
import config from 'config'

class TrackInfo extends Component {

    static propTypes = {
        track: PropTypes.object,
        image: PropTypes.string
    }

    render () {
        const
            { track, image } = this.props

        if ( !track ) return null

        const
            picture = track.picture
                ? `${config.api.images}/tracks/${track.picture}`
                : (
                    image
                        ? `${config.api.images}/${image}`
                        : null
                )

        return (
            <React.Fragment>
                <div className="track-picture-container">
                    {
                        picture && (
                            <span style={{ backgroundImage: `url(${picture})` }} />
                        )
                    }
                </div>
                <div className="track-info">
                    <h5>
                        { extract.title( track ) }
                    </h5>
                    <small>
                        { extract.author( track ) }
                    </small>
                </div>
            </React.Fragment>
        )
    }
}

export default TrackInfo
