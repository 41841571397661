/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { Track } from 'components'
import './tracks.scss'

class Tracks extends Component {

    static propTypes = {
        canScroll: PropTypes.bool,
        playing:   PropTypes.bool,

        tracks:  PropTypes.array,
        current: PropTypes.any,

        onPlay:  PropTypes.func,
        onPause: PropTypes.func
    }

    play = item => () => this.props.onPlay( item )

    track = data =>
        {
            const
                { current, playing, canScroll } = this.props,
                active = current && current.id === data.id
                
            return (
                <li className="track-item" key={data.id}>
                    <Track
                        canScroll = { canScroll }
                        playing   = { playing }

                        data    = { data }
                        current = { current }
                        
                        onPlay  = { active && playing ? this.props.onPause : this.play(data) }
                    />
                </li>
            )
        }

    render () {
        const
            { tracks } = this.props

        return (
            <div className="tracks">
                <ul className="tracks-list">
                    {
                        tracks && tracks.filter( ( t, i ) => tracks.indexOf( t ) === i ).map( this.track )
                    }
                </ul>
            </div>
        )
    }
}

export default Tracks
