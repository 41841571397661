import { request, copy } from 'tools'
import * as api from './api'

export const getLibrary = request.requestAndSave(
    'storage.library.get',
    user => api.getLibrary( user ),
    ( event, state ) => ({
        ...state,
        library: event.data
    })
)

export const createPlaylist = request.requestAndSave(
    'storage.library.playlist.create',
    body => api.createPlaylist( body ),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        return {
            ...state,
            library: {
                ...state.library,
                playlists: [
                    event.data,
                    ...state.library.playlists,
                ]
            }
        }
    }
)

export const updatePlaylist = request.requestAndSave(
    'storage.library.playlist.update',
    body => api.updatePlaylist( body ),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        const
            request = JSON.parse( event.config.data ),
            playlists = copy.array( state.library.playlists ),
            found = playlists.find( p => p.id === request.id ),
            index = playlists.indexOf( found )

        if ( found ) {
            playlists[index] = event.data
        }

        return {
            ...state,
            library: {
                ...state.library,
                playlists
            }
        }
    }
)