/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'

/* APPLICATION */
import { Track } from 'components'
import './tracks-select.scss'

class TracksSelect extends Component {

    static propTypes = {
        tracks:   PropTypes.array,
        selected: PropTypes.array,
        onSelect: PropTypes.func 
    }

    select = item => () => this.props.onSelect( item )
    selected = item => !!this.props.selected.find( t => t.id === item.id )

    track = data =>
        {
            return (
                <li className="track-item track-item-selection" key={data.id}>
                    <div className="track-item-selector-container">
                        <Checkbox
                            checked  = { this.selected( data ) }
                            onChange = { this.select( data ) }
                        />
                    </div>
                    <Track
                        data    = { data }
                    />
                </li>
            )
        }

    render () {
        const
            { tracks } = this.props

        return (
            <div className="tracks">
                <ul className="tracks-list">
                    {
                        tracks && tracks.filter( ( t, i ) => tracks.indexOf( t ) === i ).map( this.track )
                    }
                </ul>
            </div>
        )
    }
}

export default TracksSelect
