import React from 'react'

import { generate } from 'tools'

const scrolls =
    {
        renderTrackVertical:   props => <div {...props} className="v-scroll-track" />,
        renderThumbVertical:   props => <div {...props} className="v-scroll-thumb" />,
        renderTrackHorizontal: props => <div {...props} className="h-scroll-track" />,
        renderThumbHorizontal: props => <div {...props} className="h-scroll-thumb" />,
        hideTracksWhenNotNeeded: true
    }

const dates = {
    def: 'YYYY-MM-DDTHH:mm:ssZ',
    classic: 'DD.MM.YYYY',
    nice: 'D MMMM, YYYY',
    time: 'HH:mm'
}

const
    apiUrl = process.env.REACT_APP_BASE

const config = 
    {
        title: 'React App',

        api: {
            url: apiUrl,
            images: `${apiUrl}/server/images`,
            tracks: `${apiUrl}/server/tracks`,
            upload: `${apiUrl}/api/upload`
        },

        scrolls,

        ui: {
            prefix: '_skoglyd',
            dates
        },

        drafts: {
            playlist: user => ({
                id: generate.uniq(),
                user,
                title: 'New Playlist',
                tracks: []
            })
        }
    }

export default Object.freeze( Object.assign( {}, config ) )